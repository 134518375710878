.quantityContainer {
  width: 36px;
  height: 36px;
  padding: 6px;
  text-align: center;
}

.nameContainer {
  width: 194px;
}

.reviewCartContainer {
  // height: calc(100vh - 178px);
  overflow: auto;
}

.reviewCartButton {
  box-shadow: 0px 0px 10px rgba(16, 45, 51, 0.08);
  background: #ffffff;
  position: absolute;
  // height: 114px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
