.container {
	:global {
		.ant-select-selector,
		.ant-select-selection-search {
			padding: 0px !important;
			font-size: 0.875rem;
		}
		.ant-select-selector {
			width: calc(100% - 30px) !important;
			overflow: hidden;
		}
		.ant-select-selector,
		.ant-select-selection-search-input,
		.ant-select-selection-search {
			height: 100% !important;
			right: 0px !important;
			left: 0px !important;
			&:focus,
			&:active {
				outline: 0 !important;
				box-shadow: none !important;
			}
			outline: 0 !important;
			box-shadow: none !important;
			border: 0px !important;
		}
		.ant-select-selection-search-input {
			padding: 0.25rem 1rem !important;
		}
		.ant-select-selection-placeholder,
		.ant-select-selection-item {
			font-size: 0.875rem;
			display: flex !important;
			align-items: center !important;
			padding: 0.25rem 1rem !important;
		}
	}
}

.select {
	overflow: hidden;
	:global {
		.ant-select-arrow {
			transition: transform 0.2s;
			transform-origin: center center;
		}
	}
}

.required {
	border-top: 25px solid #cbe0e6;
	border-left: 25px solid transparent;
	top: 0px;
	right: 0px;
	position: absolute;

	&.error {
		border-top-color: #c83636;
	}

	> .icon {
		position: absolute;
		top: -25px;
		right: 0px;
		font-size: 7px;
		color: #46b0c6;

		&.error {
			color: #ffffff;
		}
	}
}
