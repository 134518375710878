.container {
	height: 1.125rem;
	width: 1.125rem;
	:global {
		.ant-checkbox-input {
			height: 1.125rem;
			width: 1.125rem;
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #46b0c6;
			border-color: #46b0c6;
			&:after {
				border-color: #ffffff;
			}
		}

		.ant-checkbox:hover .ant-checkbox-inner {
			border-color: #46b0c6;
		}

		.ant-checkbox-indeterminate .ant-checkbox-inner {
			background-color: #46b0c6;
			&::after {
				height: 5%;
				background-color: #ffffff;
			}
		}
	}
}
