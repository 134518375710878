.container {
	border-radius: 4px;
	box-shadow: 0px 0px 2px 0.1px rgba($color: #000000, $alpha: 0.1);
	transition: box-shadow 0.5s;
	&:not(.list) {
		min-height: 180px;
		.preview {
			margin-left: 1rem;
		}
		.description {
			text-overflow: ellipsis;
			overflow: hidden;

			white-space: pre-line;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			max-height: 40px;
			-webkit-box-orient: vertical;
		}
	}

	&:hover {
		box-shadow: 0px 0px 10px 0.1px rgba($color: #000000, $alpha: 0.2);
		.app {
			box-shadow: inset 0 0 20px rgba($color: #000000, $alpha: 0.1);
		}
		.line {
			box-shadow: inset 0 0 0.1px rgba($color: #000000, $alpha: 0.2);
		}
	}
}

.list {
	padding-bottom: 10px;
	margin-bottom: 10px;
	.actions {
		display: flex;
		justify-content: flex-end;
		height: 35px;
	}
	.preview {
		margin-right: 20px;
	}
	.header {
		height: 20px;
	}
}

.description {
	font-size: 14px;
}
