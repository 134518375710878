.body {
	margin-bottom: 160px;
	@media screen and (min-width: 640px) {
		margin-bottom: 200px;
	}
}

.price {
	white-space: nowrap;
	flex-grow: 1;
	text-align: right;
}
