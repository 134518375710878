.icon {
	transition: transform 0.2s;
}

.error {
	border-color: #c83636;
}

.required {
	border-top: 25px solid #cbe0e6;
	border-left: 25px solid transparent;
	top: 0px;
	right: 0px;
	position: absolute;

	&.error {
		border-top-color: #c83636;
	}

	> .icon {
		position: absolute;
		top: -25px;
		right: 0px;
		font-size: 7px;
		color: #46b0c6;

		&.error {
			color: #ffffff;
		}
	}
}
