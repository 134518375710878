.container {
  :global {
    .ant-radio-checked .ant-radio-inner {
      border-color: rgba(22, 24, 24, 1) !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: white;
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: rgba(22, 24, 24, 1);
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: rgba(22, 24, 24, 1);
    }

    .ant-radio-checked .ant-radio-inner:focus {
      border-color: rgba(22, 24, 24, 1);
    }
  }
}
