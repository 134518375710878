.container {
	box-shadow: 0px 0px 2px rgba(70, 76, 78, 0.02),
		0px 2px 10px rgba(70, 76, 78, 0.05);
}

.content {
	width: 100%;
	margin: auto;
}

.logo {
	height: 30px !important;
}

.headerNav {
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	color: #102d33;
}

.headerItem {
	&:first-child:not(.dropdown) {
		margin-left: 50px;
	}
}

.headerNavText {
	font-size: 16px;
	font-weight: 600;
}

.antDrawer .ant-drawer-body {
	padding: 0px;
}
