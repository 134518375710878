:global {
	.ant-modal-body {
		padding-top: 0px;
	}
	.ant-modal-content {
		border-radius: 4px !important;
	}
}

.fullModal {
	:global {
		.ant-modal-content {
			height: calc(100% - 50px);
			position: fixed;
			width: 100%;
			left: 0px;
			top: 50px;
		}
	}
}
